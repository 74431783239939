/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as SkylandIcon } from "../assets/Skyland.svg";
import { ReactComponent as CheckedIcon } from "../assets/Checked.svg";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useLocation, useParams } from "react-router-dom";
import { PageTitle } from "../booking/complex/PageTitle";
import { useStyles } from "./styles";
import { Button, Typography } from "@mui/material";
import Loading from "../shared/components/Loading";
import { TimeScheduleService } from "../booking/services/time-schedule.service";
import { observableAxios } from "../system/rxjs-axios";
import { FC, useEffect, useState } from "react";
import { Reservation } from "../booking/model/timeslot.model";
import { TextInput } from "../booking/shared/components/TextInput";
import { Trans, useTranslation } from "react-i18next";
import { useActivity } from "../booking/activity/useActivity";
import { useNavigate } from "../booking/shared/hooks";
import { WaverFromService } from "../waiver/services/waver-from.service";
import { SignatureLogService } from "../signature-log/signature-log.service";

function getMainActivityIdFrom(stream: Reservation[]): string {
  return stream.filter(x => x.type === "ActivityReserved")
    .reduce((max, v) => max.duration > v.duration ? max : v, { duration: 0 } as any)?.referenceId ?? "";
}

type Props = {
  Vipps?: FC<any>;
  PartialUpdate?: FC<any>;
  route?: string;
};

export const RefNumber: FC<Props> = ({ Vipps, PartialUpdate, route="ref-number" }) => {
  const { search } = useLocation();

  const mobile = decodeURIComponent(new URLSearchParams(search).get("mobile") ?? "");
  const { t } = useTranslation("ref-number");

  const classes = useStyles();

  const navigate = useNavigate();

  const [timeScheduleService] = useState(
    new TimeScheduleService(observableAxios)
  );
  const { refCode } = useParams<{ refCode?: string }>();

  const [refNumber, setRefNumber] = useState<string>("");

  const [reservationId, setReservationId] = useState<string>("");
  const [isGroup, setIsGroup] = useState<boolean>(false);

  const [notFound, setNotFound] = useState(false);
  const [stream, setStream] = useState<Reservation[]>();
  const [waverIsSigned,setWaverIsSigned]  = useState({waverIsSigned:false,isLoaded:false});
  const [waverService] = useState(
    new WaverFromService(
      observableAxios,
      new SignatureLogService(observableAxios)
    )
  );

  useEffect(() => {
    refCode &&
      timeScheduleService.getReservationStreamByRefNumber(refCode).subscribe(
        (s) => {
          setStream(s);
          const reservationId = s[0]?.reservationId ?? "";
          if(reservationId){
            setReservationId(reservationId);
            const group = timeScheduleService.getGroupReservationById(reservationId);
            setIsGroup(!!group);
          }
        },
        (_) => setNotFound(true)
      );
  }, [refCode]);

  useEffect(()=>{
    mobile && waverService.confirmWaiverExist(mobile).subscribe((d)=>{
      setWaverIsSigned({ waverIsSigned: d.contractExists, isLoaded: true });
    });
  }, [mobile]);

  const activityId = getMainActivityIdFrom(stream ?? []);
  const { activity } = useActivity(activityId);
  
  const mainActivityReservation = stream?.find(x => x.referenceId === activityId);

  const loaded = !!stream;
  const siteRef = activity?.site?.prittyUrl;
  return (
    <div className={classes.root}>
      <PageTitle resourceName={activity?.site?.name ?? "Skyland"} Icon={<SkylandIcon />} />
      {reservationId && PartialUpdate && (
        <PartialUpdate reservationId={reservationId} refCode={refCode} />
      )}
      <Typography className={classes.subtitleText}>
        {loaded ? t("ok.title") : ""}
        {notFound ? t("notFound.title") : ""}
        {!refCode ? t("enterCode.title") : ""}
      </Typography>
      {!refCode && (
        <TextInput
          value={refNumber}
          onChange={(e) => setRefNumber(e.target.value)}
        />
      )}
      {!refCode && (
        <Button
          className={classes.verifyButton}
          variant="contained"
          onClick={() => navigate(`/${route}/${refNumber}`)}
        >
          {t("enterCode.button")}
        </Button>
      )}
      {!loaded && !notFound && refCode && (
        <Loading className={classes.icon} size={60} />
      )}
      {loaded && <CheckedIcon className={classes.icon} />}
      {notFound && <CancelRoundedIcon className={classes.notFoundIcon} />}
      {loaded && (
        <>
          {waverIsSigned.isLoaded && !waverIsSigned.waverIsSigned && (<><Typography>
            <Trans i18nKey={"waiver.required"} ns="ref-number">
              Det er ingen registrert egenerklæring på ditt telefonnummer. Du må fylle ut egenerklæring på forhond!
            </Trans>
          </Typography>
          {siteRef && <Button
            className={classes.waiverButton}
            variant="contained"
            onClick={() => navigate(`/egenerklering/${siteRef}?mobile=${encodeURIComponent(mobile)}`)}
            >
             {t("waiver.navigate")}
          </Button>}</>)}
          <Typography>{t("ok.yourCodeIs")}</Typography>
          <Typography className={classes.refNumberText}>{refCode}</Typography>
          <Typography>
            {t("when", {
              date: mainActivityReservation?.start()?.format("DD.MM.yyyy."),
              from: mainActivityReservation?.start()?.format("HH:mm"),
              to: mainActivityReservation?.end()?.format("HH:mm"),
              count: mainActivityReservation?.count
            })}
          </Typography>
          {reservationId && Vipps && (
            <>
              <Vipps reservationId={reservationId} />
              <Typography className={classes.vippsNoteText}>
                {t('vippsNote')}
              </Typography>
            </>
          )}
          <Typography className={classes.noteText}>
            {isGroup && <Trans i18nKey={"ok.noteGroup"} ns="ref-number">
              <span>
                Remember to show up 15 min before the time to check in.
                Cancellation must be made at least 48 hours in advance, call us
                at{" "}
              </span>
              <b>40418182</b>. Thank you in advance!
            </Trans>}
            {!isGroup && <Trans i18nKey={"ok.noteRegular"} ns="ref-number">
              <span>
                Remember to show up 15 min before the time to check in.
                Cancellation must be made at least 48 hours in advance, call us
                at{" "}
              </span>
              <b>40418182</b>. Thank you in advance!
            </Trans>}
          </Typography>
        </>
      )}
    </div>
  );
};
