/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IMAGES_API_URL } from "../../environment";
import { useActivities, useAllActivities } from "../activity/useActivity";
import { SelectableImage } from "../shared/components/SelectableImage";
import { ReservationContext } from "../shared/state";
import { ComplexBookingContext, getSelectedCoversDuration, getSelectedDuration } from "./state";
import moment from "moment";
type Props = {
  locked?: boolean;
};

const MAX_NUMBER_OF_SUBACTIVITIES = 10;

export const Activities: FC<Props> = ({ locked = false }) => {
  const { state: complexState, dispatch } = useContext(ComplexBookingContext);
  const { state } = useContext(ReservationContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [activityIds] = useState(query.getAll("activityId"));

  const isPackage = !!activityIds.length;
  
  const { activities: allActivities, isLoading: isLoadingAll } = useAllActivities();
  const { activities, isLoading } = useActivities(activityIds ?? []);

  useEffect(() => {
    dispatch({ type: 'SET_LOADING_ACTIVITIES', data: isLoading || isLoadingAll });
  }, [isLoading, isLoadingAll]);

  useEffect(() => {
    state.activity && dispatch({
      type: "ADD_ACTIVITY",
      data: state.activity,
    });
    // if(state?.activity?.defaultSubActivity){
    //   dispatch({
    //     type: "ADD_ACTIVITY",
    //     data: state?.activity?.defaultSubActivity,
    //   });
    // }
  }, [state.activity]);

  useEffect(() => {
    activities.forEach(a => {
      dispatch({
        type: "ADD_ACTIVITY",
        data: a,
      });
    });
  }, [activities]);

  const selectedCoversDuration = getSelectedCoversDuration(state,complexState);
  const defaultActivityDuration = complexState.defaultActivityDuration;
  const remainingDuration = defaultActivityDuration>0?defaultActivityDuration:0;
  const defaultSubActivity = allActivities?.find(x => x.id == state?.activity?.defaultSubActivityId);
  
  useEffect(()=>{
    if(defaultActivityDuration>=0)
      dispatch({type:"SET_DEFAULT_ACTIVITY", data:{defaultActivityDuration,defaultSubActivity}});
  },[defaultActivityDuration,defaultSubActivity])
  return (
    <>
      {allActivities
        .filter(x => x.siteId === state.activity?.siteId && x.bookableAsSubActivity)
        .filter(x => activityIds.includes(x.id) || !isPackage || x.id == state?.activity?.defaultSubActivityId)
        .map((activity, i) => (
        <SelectableImage
          key={activity.id + i}
          label={`${activity?.name} ${activity.id == state?.activity?.defaultSubActivityId && !selectedCoversDuration? "("+ remainingDuration+" min)":"(30 min)"}`}
          selected={!!complexState.activities.find(a => a.id === activity?.id) || (activity.id == state?.activity?.defaultSubActivityId && !selectedCoversDuration)}
          imageUrl={`${IMAGES_API_URL}/${activity.imageUrl}`}
          onChange={(c) => {
            const blockRemoveDefault = activity.id == state?.activity?.defaultSubActivityId && !selectedCoversDuration;
            if(locked || blockRemoveDefault) return;
            if(c && complexState.activities.length < MAX_NUMBER_OF_SUBACTIVITIES) {
              dispatch({
                type: "ADD_ACTIVITY",
                data: activity,
              });
            } else {
              !isPackage && dispatch({
                type: "REMOVE_ACTIVITY",
                data: activity,
              });
            }
          }}
        />
      ))}
    </>
  );
};
