import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  subtitleText: {
    fontSize: '20px !important',
    marginTop: '3em'
  },
  icon: {
    margin: '1.5em 0',
  },
  notFoundIcon: {
    margin: '0.25em 0',
    fontSize: '64px',
    color: theme.palette.primary.main
  },
  refNumberText: {
    fontSize: '64px !important',
    fontWeight: 700,
    color: theme.palette.primary.main
  },
  noteText: {
    marginTop: '2.5em',
    maxWidth: '700px',
    fontSize: '14px'
  },
  vippsNoteText: {
    maxWidth: '500px',
    fontSize: '13px'
  },
  verifyButton: {
    marginTop: '2.5em'
  },
  vippsButton: {
    margin: '1.5em 0'
  },
  vippsImage: {
    maxWidth: '100px'
  },
  alergiesContainer: {
    color: '#fff',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.border?.radius,
    padding: '1em',
    margin: '1em 0.5em 0 0.5em'
  },
  waiverButton:{
    marginTop: '2.5em',
    marginBottom: '2.5em'
  },
  vippsBasicButton: {
    marginTop: '1em'
  }
})
);