import {
  DaySchedule,
  DayScheduleTypes,
  PriceInfo,
} from "../../shared/model/day-schedule.model";
import { TimeSlot, Reservation, ActivityReserved } from "./timeslot.model";
import { CalendarTool } from "../../system/calendartool";
import { Schedule } from "./schedule.model";
import moment, { duration, Moment } from "moment";
import { Observable } from "rxjs/internal/Observable";
import { observableAxios } from "../../system/rxjs-axios";
import { BookingService } from "../services/booking.service";
import { forkJoin } from 'rxjs';
import { Guid } from "../../system/guid";
import { Site } from "../../shared/model/site";
import { getResourceAvailableTimeSlots } from "../services/timeSlotUtils";

export class DayRuleTimeInterval {
  constructor(
    public days: Array<number>,
    public startTime: any,
    public endTime: any,
    public validFrom: moment.Moment | null = null,
    public validTo: moment.Moment | null = null
  ) {}
}
export class TimeIntervalInformation<T> extends DayRuleTimeInterval {
  constructor(
    public days: Array<number>,
    public startTime: any,
    public endTime: any,
    public validFrom: moment.Moment | null = null,
    public validTo: moment.Moment | null = null,
    public infos: T
  ) {
    super(days, startTime, endTime, validFrom, validTo);
  }
  public static deSerialize<T>(data: any, deSerializer:(x:any)=>T) {
    return new TimeIntervalInformation<T>(
      data.days,
      data.startTime,
      data.endTime,
      CalendarTool.justDateOrNull(data.validFrom),
      CalendarTool.justDateOrNull(data.validTo),
      deSerializer(data.info)
    );
  }
}
export class Resource {
  private schedule: Schedule;
  private readonly bookingService = new BookingService(observableAxios);
  constructor(
    public id: string,
    public name: string,
    public tenantId: string,
    public siteId: string,
    public capacity: number,
    public capacityExceptions: Array<TimeIntervalInformation<number>>,
    public concurrentReservations: number,
    public createdOn: moment.Moment | null = null,
    public prittyUrl: string,
    public schedules: Array<DaySchedule>
  ) {
    // TOOD handle error when schedules == null
    this.schedule = new Schedule(moment(), schedules, id, [], this.concurrentReservations);
  }
  public static deSerialize(data: any) {
    return new Resource(
      data?.id,
      data?.name,
      data?.tenantId,
      data?.siteId,
      data?.capacity,
      data?.capacityExceptions && data?.capacityExceptions.map((x:any) => TimeIntervalInformation.deSerialize(x,(info)=>parseInt(info))),
      data?.concurrentReservations,
      CalendarTool.justDateOrNull(data?.createdOn),
      data?.prittyUrl,
      data?.schedules && data?.schedules.map((x: any) => DaySchedule.deSerialize({
        ...x,
        interval: data?.interval ?? x.interval,
        concurrentStarts: data?.concurrentStarts ?? 0
      }))
    );
  }

  public getVacantTimeSlots(
    date: moment.Moment,
    requestedCapacity: number = 1,
    requestedDuration?: number
  ): Array<TimeSlot>|null {
    return this.schedule.getVacantTimeSlots(date, requestedCapacity, requestedDuration);
  }
  public makeReservation(timeSlot: TimeSlot, price: number, count: number, mobile: string, reservationId: Guid): Observable<Reservation> {
    return this.bookingService.makeReservation(this.schedule,timeSlot,price,count,mobile,reservationId);
  }
  public createReservation(timeSlot: TimeSlot, price: number, count: number, mobile: string, reservationId: string): Reservation {
    const reservation = this.bookingService.createReservation(this.schedule,timeSlot,price,count,mobile,reservationId);
    (reservation as any).referenceId = this.id;
    return reservation;
  }
  public setReservations(date: Moment, reservations: Reservation[]): void {
    this.schedule.setReservations(date, reservations);
  }
}

export enum ActivityType {
  Regular = 0,
  Group = 1,
  Break = 2,
  PayPrResource = 3,
}
export enum ActivityPriceModel{
  AccumulateSubActivityCosts = 0,
  UseMainActivityCostOnly = 1,
}
export class Activity {
  public reservations: Array<Reservation>=[];
  public constructor(
    public id: string,
    public name: string,
    private description: string,
    public tenantId: string,
    public siteId: string,
    private requiredResourceIds: Array<string>,
    private requiredResources: Array<Resource>,
    private priceInfo: Array<PriceInfo>,
    private priceInfosExceptions: Array<TimeIntervalInformation<Array<PriceInfo>>>,
    private prittyUrl:string,
    public createdOn: moment.Moment | null = null,
    public site: Site | null = null,
    public minGroupSize: number = 0,
    public imageUrl: string = '',
    public activityType: ActivityType = ActivityType.Regular,
    public minInterval: number = 15,
    public concurrentStarts: number = 0,
    public isBirthday: boolean = false,
    public bookableAsSubActivity: boolean = true,
    public defaultSubActivityId: string = Guid.empty().toString(),
    public defaultSubActivity?: Activity,
    public priceModel: ActivityPriceModel = ActivityPriceModel.AccumulateSubActivityCosts,
  ) {}
  getTenantId(): string {
    return this.tenantId;
  }
  public static deSerialize(data: any): Activity {
    if(data.name == "Hopping"){
      console.log(data);
    }
    return new Activity(
      data.id,
      data.name,
      data.description,
      data.tenantId,
      data.siteId,
      data.requiredResourceIds,
      data.requiredResources && data.requiredResources.map((x: any) => Resource.deSerialize({
        ...x,
        interval: data?.minInterval ?? x.interval,
        concurrentStarts: data?.concurrentStarts ?? 0
      })),
      data.priceInfos && data.priceInfos.map(PriceInfo.deSerialize),
      data.priceInfosExceptions && data.priceInfosExceptions.map((x:any)=> TimeIntervalInformation.deSerialize<PriceInfo>(x,(info)=>info.map(PriceInfo.deSerialize))),
      data.prittyUrl,
      data.createdOn && CalendarTool.justDateOrNull(data.createdOn),
      data.site && Site.deSerialize(data.site),
      data?.minGroupSize ?? 0,
      data?.imageUrl ?? "",
      data?.activityType ?? ActivityType.Regular,
      data?.minInterval ?? 15,
      data?.concurrentStarts ?? 0,
      data?.isBirthday ?? false,
      data?.bookableAsSubActivity ?? true,
      data?.defaultSubActivityId,
      data?.defaultSubActivity? Activity.deSerialize(data?.defaultSubActivity):undefined,
      data?.priceModel ?? ActivityPriceModel.AccumulateSubActivityCosts
    );
  }
  
  public getVacantTimeSlots(
    date: moment.Moment,
    requestedCapacity: number = 1,
    requestedDuration?: number
  ): Array<TimeSlot>|null {
    const allTimeSlots = this.requiredResources
      .map(x=>{
        var r = x.getVacantTimeSlots(date, requestedCapacity, requestedDuration ?? this.getMinimumDuration());
        return r;
      })
      .reduce<TimeSlot[]>((acc, curr) => [...acc, ...(curr || [])], []);
    var timeslots = getResourceAvailableTimeSlots(allTimeSlots, this.requiredResources.length);
    if(this.concurrentStarts){
      timeslots = timeslots.filter(x=>(this.countSameTypeReservationsStartingSimultaneously(x)+1) <= this.concurrentStarts);
    }
    return timeslots;
  }

  private countSameTypeReservationsStartingSimultaneously(timeslot: TimeSlot): number {
    const filtered = this.reservations.filter((reservation) => {
      return reservation.count !==0 && reservation.start().isSame(timeslot.start(), 'minute');
    });
    const count = filtered.reduce((acc,curr)=>acc+(curr.count>=0?1:-1),0);
    return count;
  }

  public getMinimumDuration(): number {
    return this.priceInfo.reduce<number>((acc, curr) => acc > curr.duration ? curr.duration : acc, 9999);
  }
  
  public makeReservation(timeSlot: TimeSlot, price: number, count: number, mobile: string): Observable<Array<Reservation>> {
    var reservationId = Guid.newGuid();
    var reservations = this.requiredResources
    .map(x=>x.makeReservation(timeSlot,price, count, mobile,reservationId));
    return forkJoin(reservations);
  }
  
  public createReservation(timeSlot: TimeSlot, price: number, count: number, mobile: string, reservationId: string): Reservation {
    var reservation = new ActivityReserved(
      timeSlot.startTime,
      timeSlot.duration,
      count,
      mobile,
      this.id,
      this.tenantId,
      price,
      DayScheduleTypes.Regular
    );
    reservation.reservationId = reservationId;
    return reservation;
  }

  public createReservations(timeSlot: TimeSlot, price: number, count: number, mobile: string, withResources: boolean = true): Array<Reservation> {
    var reservationId = Guid.newGuid().toString();
    var resourceReservations = withResources ? this.requiredResources.map(x=>x.createReservation(timeSlot,price, count, mobile,reservationId)) : [];
    return [...resourceReservations, this.createReservation(timeSlot,price, count, mobile,reservationId)];
  }

  private getExceptions(date: moment.Moment): Array<TimeIntervalInformation<Array<PriceInfo>>> {
    // var exceptions = this.priceInfosExceptions.filter(x=>x.validFrom?.isBefore(date) && x.validTo?.isAfter(date));
    var exceptions = this.priceInfosExceptions.filter(x=>
      (x.validFrom == null && x.validTo != null && x.validTo.isAfter(date)) ||
      (x.validFrom != null && x.validTo == null && x.validFrom.isBefore(date)) ||
      (x.validFrom?.isBefore(date) && x.validTo?.isAfter(date))
    );
    return exceptions.sort((a:TimeIntervalInformation<Array<PriceInfo>>,b:TimeIntervalInformation<Array<PriceInfo>>)=>{
        var result = 0;
        
        if(a.validFrom && a.validTo && b.validFrom && b.validTo)
          result = a.validTo.diff(a.validFrom) - b.validTo.diff(b.validFrom);
        else{
          var diffA = a.validFrom?.diff(date);
          var diffB = b.validFrom?.diff(date);
          var diffATo = a.validTo?.diff(date);
          var diffBTo = b.validTo?.diff(date);
          result = diffA && diffB? (diffA < diffB ? -1 : 1):
          diffA && !diffB? -1:
          !diffA && diffB? 1:0;
          if(result == 0)
            result = diffATo && diffBTo? (diffATo < diffBTo ? -1 : 1):
            diffATo && !diffBTo? -1:
            !diffATo && diffBTo? 1:0;
        }
        return result;
      });
  }

  public getDurations(date: moment.Moment, campaign: string) {
    var exceptions = this.getExceptions(date);
    
    var durations = this.priceInfo ?? [];
    if(exceptions.length>0){
      durations = exceptions[0].infos;
      if(exceptions.length>1){
        durations = exceptions.reduce((acc,curr)=>acc && acc.validFrom && curr && curr.validFrom && acc.validFrom?.diff(acc?.validTo)>curr?.validFrom?.diff(curr?.validTo)?acc:curr,exceptions[0]).infos;
      }
      
    }
    durations = campaign !== ""
        ? durations.filter((x) => x.campaign === campaign)
        : durations.filter((x) => x.campaign === null || x.campaign === "");
    return durations;
  }

  public getAllPriceInfos(campaign: string) {
    var durations = this.priceInfo ?? [];
    durations = campaign !== ""
        ? durations.filter((x) => x.campaign === campaign)
        : durations.filter((x) => x.campaign === null || x.campaign === "");
    return durations;
  }

  public getPriceInfos(date: moment.Moment): Array<PriceInfo> | null {
    return null;
  }



  public getPriceInfoForSubActivity(date: Moment){    
    var priceInfosExceptions = this.getExceptions(date);
    var priceInfo = priceInfosExceptions?.[0]?.infos?.find(x => x.subActivityPrice);
    if(this.name =="Hopping"){
      console.log(priceInfo);
    }
    if(!priceInfo){
      priceInfo = this.priceInfo.find(x => x.subActivityPrice);
    }
    if(this.name =="Hopping"){
      console.log(this.priceInfo);
      console.log(this.priceInfosExceptions);
      console.log(priceInfo);
    }
    return priceInfo;
  }
  public getRequiredResourceIds(): string[] {
    return this.requiredResourceIds;
  }

  public getRequiredResources(): Resource[] {
    return this.requiredResources;
  }

  public setReservationsToResources(date: Moment, reservations: Reservation[]) {
    this.reservations = reservations.filter(r=>r.referenceId === this.id && r.type == "ActivityReserved");
    this.requiredResources.forEach(x => x.setReservations(date, reservations.filter(r => r.referenceId === x.id && r.type === "Reservation")));
  }

  public capacity() {
    return this.requiredResources.reduce((acc,r)=> Math.min(acc,r.capacity),9999999999);
  }
}
