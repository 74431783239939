/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import * as Yup from "yup";
import { Person } from "./Person.model";
import { FC, useCallback, useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { TextInput } from "../booking/shared/components/TextInput";
import { ColoredText } from "../booking/shared/components/ColoredText";
import { useInputStyles } from "../booking/complex/styles";
import { SelectInput } from "../booking/shared/components/SelectInput";
import { TextButton } from "../booking/shared/components/TextButton";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import { useDetailsStyles } from "./styles";
import { ParentFormGroup } from "./ParentFormGroup";
import { debounced } from "../booking/shared/utils";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  onSubmit: (values: any, valid: boolean) => any;
  submitButtonLabel?: string;
  showCheckboxes?: boolean;
  mobile?: string;
  includeChildren?: boolean;
};

export const DetailsForm: FC<Props> = ({ onSubmit, showCheckboxes = true, mobile, includeChildren = false }) => {
  const { t } = useTranslation("details");

  const classes = useDetailsStyles();
  const classesInput = useInputStyles();

  const [personalConsentChecked, setPersonalConsentChecked] = useState(true);
  const [marketingConsentChecked, setMarketingConsentChecked] = useState(false);
  const [contact, setContact] = useState<any>({});
  const [contactValid, setContactValid] = useState<boolean>(false);

  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<string[]>([]);
  const [ageErrorMessage, setAgeErrorMessage] = useState<string[]>([]);

  const newChild = {
    firstName: "",
    age: "" as any,
  };
  const [children, setChildren] = useState(includeChildren ? [newChild] : []);

  useEffect(() => {
    if(!children.length && includeChildren) {
      setChildren([newChild]);
    }
  }, [children.length, includeChildren]);

  const childrenValidation = Yup.object().shape({
    children: Yup.array().min(1).of(
      Yup.object().shape({
        firstName: Yup.string().required(t('children.errors.name')),
        age: Yup.number().typeError(t('children.errors.age')),
      })
    ),
  });

  const removeChild = (i: number) => {
    setChildren((c) => {
      c.splice(i, 1);
      return [...c];
    });
  };

  const debouncedChange = useCallback(
    debounced(function (value: any, isValid: boolean) {
      onSubmit(value, isValid);
    }, 700),
    []
  ) as any;

  const setFirstNameErrorMessageAt = (index: number, message: string) => {
    setFirstNameErrorMessage(prev => {
      prev[index] = message;
      return prev;
    });
  }

  const setAgeErrorMessageAt = (index: number, message: string) => {
    setAgeErrorMessage(prev => {
      prev[index] = message;
      return prev;
    });
  }

  useEffect(() => {
    const firstName = contact.name?.split(" ")?.[0] ?? "";
    const lastName = contact.name?.split(" ")?.splice(1)?.join(" ") ?? "";
    const value = {
      contactPerson: new Person(firstName, lastName, moment()),
      mobile: contact.mobile,
      email: contact.email,
      children,
    };
    const areChildrenValid = childrenValidation.isValidSync(value) || !includeChildren;
    debouncedChange(value, contactValid && areChildrenValid && personalConsentChecked && marketingConsentChecked);
    children.forEach((_, i) => {
      childrenValidation.validateAt(`children.${i}.firstName`, value)
        .then(() => setFirstNameErrorMessageAt(i, ""))
        .catch(e => setFirstNameErrorMessageAt(i, e.message.toString()));
      childrenValidation.validateAt(`children.${i}.age`, value)
        .then(() => setAgeErrorMessageAt(i, ""))
        .catch(e => setAgeErrorMessageAt(i, e.message.toString()));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, children, contactValid, personalConsentChecked, marketingConsentChecked]);

  return (
    <div>
      <ParentFormGroup
        label={t("parent.title")}
        onChange={(v, valid) => {
          setContact(v);
          setContactValid(valid);
        }}
        mobile={mobile}
      />
      {includeChildren && (
        <>
          <div className={classes.paddingContainer}>
            <ColoredText>{t("children.title")}</ColoredText>
          </div>
          {children.map((child, i) => (
            <div className={classes.marginContainer} key={i}>
              <div className={classesInput.fieldContainer}>
                <TextInput
                  label={'*' + t("children.nameAndSurnameLabel")}
                  className={classesInput.unsetMaxWidth}
                  value={child.firstName}
                  onChange={(e) =>
                    setChildren((c) => {
                      c[i].firstName = e.target.value;
                      return [...c];
                    })
                  }
                />
                <span className={classesInput.errorMessage}>{firstNameErrorMessage[i]}</span>
              </div>
              <div className={classesInput.fieldContainer}>
                <SelectInput
                  label={'*' + t("children.ageLabel")}
                  value={child.age}
                  onChange={(v) =>
                    setChildren((c) => {
                      c[i].age = v as number;
                      return [...c];
                    })
                  }
                  options={Array.from({ length: 17 }, (_, i) => ({
                    label: i + 1,
                    value: i + 1,
                  }))}
                />
                <span className={classesInput.errorMessage}>{ageErrorMessage[i]}</span>
              </div>
              <IconButton onClick={() => removeChild(i)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <div className={`${classes.paddingContainer} ${classes.marginContainer}`}>
            <TextButton
              variant="contained"
              onClick={() => setChildren((c) => [...c, newChild])}
            >
              <AddBoxOutlinedIcon sx={{ marginRight: "0.3em" }} />{" "}
              {t("children.addButton")}
            </TextButton>
          </div>
        </>
      )}
      {showCheckboxes && (
        <>
          <div className={`${classes.paddingContainer} ${classes.smallerText}`}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              onChange={() => setPersonalConsentChecked(!personalConsentChecked)}
              label={
                <Trans i18nKey="consent.personal" ns="details">
                  content{" "}
                  <a target="top" href="https://www.skyland.no/betingelser">
                    {" "}
                    <ColoredText>content</ColoredText>
                  </a>
                  content{" "}
                  <a target="top" href="https://www.skyland.no/personvern/">
                    {" "}
                    <ColoredText>content</ColoredText>
                  </a>
                  content
                </Trans>
              }
            />
          </div>
          <div className={`${classes.paddingContainer} ${classes.smallerText}`}>
            <FormControlLabel
              control={<Checkbox />}
              label={t("consent.marketing")}
              onChange={() => setMarketingConsentChecked(!marketingConsentChecked)}
            />
          </div>
        </>
      )}
    </div>
  );
};
